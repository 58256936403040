import React from 'react';
import teamMembers from '../static/teamMembers.json';
import styles from './tailwind.module.css';

const TeamMember = ({ name, position, bio, imageUrl }) => {
  return (
    <div className={styles.teamMember}>
      {imageUrl && (
        <img
          src={require(`../static/${imageUrl}`)}
          alt={name}
          className={styles.avatar}
        />
      )}
      <h1 className={styles.name}>{name}</h1>
      <h2 className={styles.position}>{position}</h2>
      <p>{bio}</p>
    </div>
  );
};

const Team = () => {
  return (
    <div className={styles.container} id="team">
      <div className={styles.textCenter}>
        <h1 className={styles.heading}>Meet Our Team</h1>
      </div>
      <div className={styles.grid}>
        {teamMembers.map((member, index) => (
          <TeamMember
            key={index}
            name={member.name}
            position={member.position}
            bio={member.bio}
            imageUrl={member.imageUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default Team;