import React, { useState, useEffect } from 'react';
import './Header.css'; // Ensure this file exists and is correctly linked
import logo from '../static/header_logo.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);
  // const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  const handleScroll = () => {
    setSticky(window.scrollY > 50);
  };

  // const handleResize = () => {
  //   setIsMobileView(window.innerWidth < 768);
  // };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listeners
    return () => {
      window.removeEventListener('scroll', handleScroll);
      // window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className={`header ${isSticky ? 'sticky' : ''}`}>
      <div className="logo-container-header">
        
        {/* <a href='/' className="logo-link"><span className="logo-text">4Tekies LLC &trade;</span></a> */}
        <a href='/' className="logo-link"><img alt='Logo' src={logo} className="logo-img-header" /></a>
      </div>
      <nav className={isOpen ? "nav open" : "nav"}>
        <ul>
          <li><a href="#services" onClick={() => setIsOpen(false)}>Services</a></li>
          <li><a href="#team" onClick={() => setIsOpen(false)}>Team</a></li>
          <li><a href="#contact" onClick={() => setIsOpen(false)}>Contact Us</a></li>
        </ul>
        <button className="close-btn" onClick={() => setIsOpen(false)}>X</button>
      </nav>
      <div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </header>
  );
};

export default Header;
