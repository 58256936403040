import Header from './components/header';
import Hero from './components/hero'
import Services from './components/services';
import Team from './components/team';
import Footer from './components/footer'
import Ropet from './components/ropet';
import './App.css'

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Services />
      <Ropet />
      <Team />
      <Footer />
    </div>
  );
}

export default App;