import React from 'react';
import './Footer.css'; // Make sure to create this CSS file

const Footer = () => {
  return (
    <footer className="footer">        
      <div className="footer-block">
        <div id="contact" className="contact-section">
          <h3>Contact Us</h3>
          <p>
            If you have any questions or inquiries, feel free to reach out to us at:{' '}
            <a href="mailto:info@4tekies.com">info@4tekies.com</a>
          </p>
        </div>
        <p>&copy; {new Date().getFullYear()} - 4Tekies LLC. All rights reserved.</p>
        <div className="footer-links">
          <a href="#services">Services</a>
          <a href="#team">Team</a>
          <a href="#contact">Contact Us</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
