import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Ropet.css';
import Bear from "../static/Bear_1.png"; 
import Cat from "../static/Cat_1.png";
import Dog from "../static/Dog_1.png";
import Bunny from "../static/Bunny_1.png";

const Ropet = () => {
  const images = [Cat, Dog, Bear, Bunny]; // Paths to your images

  return (
    <div className='landContainer'>
      <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} showArrows={false}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Carousel>
      <main>
        <h1>Home of RoPet<sup>™</sup>, RoCat<sup>™</sup>, RoDog<sup>™</sup>, RoBear<sup>™</sup>, and RoBunny<sup>™</sup></h1>
        <p>Get Ready for Some Exciting Innovations!</p>
        {/* Insert additional sections/content as needed */}
      </main>
    </div>
  );
};

export default Ropet;
