import React from 'react';
import './Services.css'; // Link to the CSS file for styling

const CompInfo = () => {
  return (
    <div className="info-section" id="services">
      <div className="services-container">
        <div className="service">
            <img width="100" height="100" src="https://img.icons8.com/ios/100/studio-display.png" alt="studio-display"/>
            <h3>Application Development</h3>
            <p>Our team of software designers, developers, and project managers will work with you to develop a custom product that is fully tested and designed to fit your business model.</p>
        </div>
        <div className="service">
            <img width="100" height="100" src="https://img.icons8.com/ios/100/cloud--v1.png" alt="cloud--v1"/>
            <h3>Cloud Development</h3>
            <p>If you are looking to extend or move your business into the cloud our team can help you architect and migrate your business to a modern platform.</p>
        </div>
        <div className="service">
            <img width="100" height="100" src="https://img.icons8.com/ios/100/iphone14-pro.png" alt="iphone14-pro"/>
            <h3>Mobile Applications</h3>  
            <p>Our team of mobile technology experts will work with you on your business strategy and bring your mobile or wearable technology ideas into reality.</p>
        </div>
        <div className="service">
            <img width="100" height="100" src="https://img.icons8.com/material-outlined/100/console.png" alt="console"/>
            <h3>System Integration</h3>
        <p>Using our in-depth understanding of integration techniques and workflow processes we can develop a strategic approach to overcoming business system integration roadblocks.</p>
        </div>
      </div>
      
    </div>
  );
};

export default CompInfo;
